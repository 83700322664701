import { AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH } from '../../shared/endpoints/account';
import {
    MagicLinkAuthenticateRequestBody,
    MagicLinkAuthenticateResponseBody,
} from '../../shared/requests';
import { makeRequest } from '../fetch';

type MagicLinkAuthenticateOptions = Pick<
    MagicLinkAuthenticateRequestBody,
    'email' | 'jti'
> & {
    params: string;
};

export async function magicLinkAuthenticate({
    email,
    jti,
    params,
}: MagicLinkAuthenticateOptions) {
    try {
        return await makeRequest<
            MagicLinkAuthenticateRequestBody,
            MagicLinkAuthenticateResponseBody
        >(`${AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH}${params}`, {
            method: 'post',
            body: { email, jti },
        });
    } catch (error) {
        return { success: false, message: 'clientError' };
    }
}
