import React, { useCallback, useState } from 'react';
import { useFormContext } from '@bbc-account/id-formaxe';
import { MagicLinkConfirmationBase } from '../../../auth/pages/magicLinkConfirmation';
import { useStateContext } from '../../../../modules/stateContext';
import { getSessionUrlWithUpdatedPtrt } from '../../../../../shared/magicLink';
import magicLinkAuthenticate from '../../../../services/magicLinkAuthenticateEmailFirst';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import { redirectToMagicLinkErrorPage } from '../../../../hooks/auth/useMagicLinkPolling';
import authPages from '../../../../../shared/data/authPages';

export const EmailFirstMagicLinkConfirmation = () => {
    const [initialErrors, setInitialErrors] = useState({});
    const userContext = useStateContext();
    const { fieldValues } = useFormContext();

    const {
        featureToggles,
        lang,
        ptrt: { value: ptrt },
        sequenceId,
        context,
    } = userContext;

    const { email, jti, traceId } = fieldValues.magicLink;

    const onMagicLinkAuthenticateSubmit = useCallback(async () => {
        const { sessionUrl, message } = await magicLinkAuthenticate(
            email,
            jti,
            renderQueryString.call(userContext)
        );

        if (sessionUrl) {
            const redirectUrl = getSessionUrlWithUpdatedPtrt({
                sessionUrl,
                ptrt,
                lang,
                sequenceId,
                context,
            });
            window.location.assign(redirectUrl);
        } else if (message === 'expiredTokenError') {
            redirectToMagicLinkErrorPage(userContext);
        } else if (message === 'serverError') {
            window.location.assign('/account/error');
        } else {
            setInitialErrors({
                confirmation: 'auth.magicLink.checkEmail.validation',
            });
        }
    }, [context, email, jti, lang, ptrt, sequenceId, userContext]);

    return (
        <MagicLinkConfirmationBase
            email={email}
            jti={jti}
            traceId={traceId}
            featureToggles={featureToggles}
            onMagicLinkAuthenticateSubmit={onMagicLinkAuthenticateSubmit}
            initialErrors={initialErrors}
            pageOverride={authPages.SINGLE_ENTRY_AUTH}
        />
    );
};
