import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FederatedSignInLayout from '../components/signIn/federatedSignInLayout';
import AccountLockedFederatedContainer from '../components/signIn/accountLockedFederated';
import ReauthenticationLayout from '../components/signIn/reauthenticationLayout';
import NeedHelpIndexContainer from '../components/forgotten/needHelpIndex';
import FederatedSignInLanding from '../components/signIn/federatedSignInLanding';
import NeedHelpForgottenPasswordForm from '../components/forgotten/forgottenPasswordForm';
import NeedHelpForgottenCredentials from '../components/forgotten/forgottenCredentials';
import NeedHelpForgottenPasswordConfirmation from '../components/forgotten/confirmation';
import VerifyEmailComplete from '../components/signIn/verifyEmail/complete';
import VerifyEmailAlreadyVerified from '../components/signIn/verifyEmail/alreadyVerified';
import VerifyEmailResendLimit from '../components/signIn/verifyEmail/resendLimit';
import VerifyEmailSorryContainer from '../components/signIn/verifyEmail/sorryContainer';
import VerifyEmailThanks from '../components/signIn/verifyEmail/thanks';
import { VerifyPasswordThanks } from '../components/signIn/verifyPassword/thanks';
import SignInOtsi from '../components/otsi/signIn';
import FederatedCompleted from '../components/signIn/federatedCompleted';
import AccountLockedEmailContainer from '../components/signIn/accountLockedEmail';

function SignInFederated() {
    return (
        <Switch>
            <Route
                exact
                path="/signin/federated"
                component={FederatedSignInLayout}
            />

            <Route
                path="/signin/federated/locked/email"
                component={AccountLockedEmailContainer}
            />

            <Route
                path="/signin/federated/locked"
                component={AccountLockedFederatedContainer}
            />

            <Route path="/signin/federated/otsi" component={SignInOtsi} />

            <Route
                path="/signin/federated/reauthenticate"
                component={ReauthenticationLayout}
            />

            <Route
                path="/signin/federated/help"
                component={NeedHelpIndexContainer}
            />

            <Route
                path="/signin/federated/landing"
                component={FederatedSignInLanding}
            />

            <Route
                path="/signin/federated/complete"
                component={FederatedCompleted}
            />

            <Route
                exact
                path="/signin/federated/forgotten"
                component={NeedHelpForgottenPasswordForm}
            />

            <Route
                path="/signin/federated/forgotten/credentials"
                component={NeedHelpForgottenCredentials}
            />

            <Route
                path="/signin/federated/forgotten/confirmation"
                component={NeedHelpForgottenPasswordConfirmation}
            />

            <Route
                path="/signin/federated/forgotten/verify"
                component={NeedHelpForgottenPasswordConfirmation}
            />

            <Route
                path="/signin/federated/verify/email/complete"
                component={VerifyEmailComplete}
            />

            <Route
                path="/signin/federated/verify/email/already-verified"
                component={VerifyEmailAlreadyVerified}
            />

            <Route
                path="/signin/federated/verify/email/resend-limit"
                component={VerifyEmailResendLimit}
            />

            <Route
                path="/signin/federated/verify/email/sorry"
                component={VerifyEmailSorryContainer}
            />

            <Route
                path="/signin/federated/verify/email/thanks"
                component={VerifyEmailThanks}
            />

            <Route
                exact
                path="/signin/federated/verify/password/thanks"
                component={VerifyPasswordThanks}
            />
        </Switch>
    );
}

export default SignInFederated;
