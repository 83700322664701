import { useFormContext } from '@bbc-account/id-formaxe';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore id-components does not have type declarations. Replace this component with id-components v2 when ready.
import { Button } from '@bbc-account/id-components';
import React, { ComponentProps, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../modules/stateContext';
import Track from '../../shared/analytics/track';
// eslint-disable-next-line import/no-named-as-default
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import FormError from '../../shared/formError';
import { HELP_SIGN_IN_URL } from '../../../../shared/endpoints/usingTheBbc';
import { EmailFirstPageLayout } from '../../email-first';
import {
    StyledEmailFirstPageLayoutContent,
    StyledForm,
} from './emailFirstForm.style';
import { useEmailFirstFormTracking } from './useEmailFirstFormTracking';
import { EmailFirstSignInCta, EmailFirstVerificationCta } from './cta';
import { MagicLinkCopy } from '../../auth/pages/magicLinkCopy';

type UseFormContextReturnType = ReturnType<typeof useFormContext>;

type UseFormContextReturnTypeSubmitHandler = Exclude<
    UseFormContextReturnType['handleSubmit'],
    undefined
>;

type UseFormContextReturnTypeSubmitHandlerParameters = Parameters<
    UseFormContextReturnTypeSubmitHandler
>;

type UseFormContextReturnTypeSubmitHandlerValidHandler = UseFormContextReturnTypeSubmitHandlerParameters[0];

type UseFormContextReturnTypeSubmitHandlerInvalidHandler = UseFormContextReturnTypeSubmitHandlerParameters[1];

type UseFormContextReturnTypeSubmitHandlerOptions = Exclude<
    UseFormContextReturnTypeSubmitHandlerParameters[2],
    undefined
>;

type UseFormContextReturnTypeSubmitHandlerActiveFields = UseFormContextReturnTypeSubmitHandlerOptions['activeFields'];

type StyledFormProps = ComponentProps<typeof StyledForm>;

type EmailFirstPageLayoutProps = ComponentProps<typeof EmailFirstPageLayout>;

type FormErrorProps = ComponentProps<typeof FormError>;

type MagicLinkCopyProps = ComponentProps<typeof MagicLinkCopy>;

type UseEmailFirstFormTrackingOptions = Exclude<
    Parameters<typeof useEmailFirstFormTracking>[0],
    undefined
>;

type ButtonProps = ComponentProps<typeof Button>;

type FormattedMessageProps = ComponentProps<typeof FormattedMessage>;

type EmailFirstFormProps = Partial<
    Pick<
        EmailFirstPageLayoutProps,
        | 'className'
        | 'heading'
        | 'hideBackButton'
        | 'layoutContentContainerClassName'
        | 'onBackButtonClick'
        | 'pageId'
        | 'secondaryContent'
    >
> &
    Pick<StyledFormProps, 'children'> &
    Pick<UseEmailFirstFormTrackingOptions, 'selectedCountry'> & {
        activeFields?: UseFormContextReturnTypeSubmitHandlerActiveFields;
        name?: FormErrorProps['name'];
        onMagicLinkSubmit?: MagicLinkCopyProps['onSubmit'];
        onSubmit?: UseFormContextReturnTypeSubmitHandlerValidHandler;
        onSubmitInvalid?: UseFormContextReturnTypeSubmitHandlerInvalidHandler;
        formError?: FormErrorProps['error'];
        submitButtonId?: ButtonProps['id'];
        submitButtonLabel?: FormattedMessageProps['id'];
    } & {
        withSignInCta?: boolean;
        withMagicLinkCta?: boolean;
        withEmailVerificationCta?: boolean;
        withHelpLink?: boolean;
    };

export const EmailFirstForm = ({
    activeFields,
    children,
    className,
    formError,
    heading,
    hideBackButton,
    layoutContentContainerClassName,
    name,
    onBackButtonClick,
    onSubmit,
    onSubmitInvalid,
    pageId,
    secondaryContent,
    selectedCountry,
    submitButtonId = 'submit-button',
    submitButtonLabel = 'register.button.submit.value',
    withSignInCta,
    withMagicLinkCta,
    withEmailVerificationCta,
    withHelpLink,
    onMagicLinkSubmit,
}: EmailFirstFormProps) => {
    const {
        location: { isUk },
    } = useStateContext();

    const { trackSubmitMetadata } = useEmailFirstFormTracking({
        selectedCountry,
    });

    const { handleSubmit, fieldValues } = useFormContext();

    const formErrorWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (formError && formErrorWrapperRef.current) {
            const {
                y: formErrorY,
            } = formErrorWrapperRef.current.getBoundingClientRect();

            const { scrollY } = window;

            const yOffset = 50;

            const y = formErrorY + scrollY - yOffset;

            if (formErrorY < yOffset) {
                window.scrollTo({ behavior: 'smooth', top: y });
            }
        }
    }, [formError]);

    return (
        <EmailFirstPageLayout
            className={className}
            heading={heading}
            hideBackButton={hideBackButton}
            layoutContentContainerClassName={layoutContentContainerClassName}
            onBackButtonClick={onBackButtonClick}
            pageId={pageId}
            secondaryContent={secondaryContent}
        >
            <StyledEmailFirstPageLayoutContent>
                <div ref={formErrorWrapperRef}>
                    <FormError
                        error={formError}
                        isAssertive
                        migrateToIdComponents
                        name={name}
                    />
                </div>
                <StyledForm
                    data-testid="form"
                    noValidate
                    onSubmit={handleSubmit?.(onSubmit, onSubmitInvalid, {
                        activeFields,
                    })}
                >
                    {children}
                    <Track metadata={trackSubmitMetadata} ignoreViews>
                        <Button id={submitButtonId} isFullWidth isSubmit>
                            <FormattedMessage id={submitButtonLabel} />
                        </Button>
                    </Track>
                </StyledForm>
                {withSignInCta && <EmailFirstSignInCta />}
                {withMagicLinkCta && (
                    <div className="u-margin-top-quad">
                        <MagicLinkCopy
                            email={fieldValues?.username}
                            onSubmit={onMagicLinkSubmit}
                        />
                    </div>
                )}
                {withEmailVerificationCta && <EmailFirstVerificationCta />}
            </StyledEmailFirstPageLayoutContent>
            {withHelpLink && (
                <div className="u-margin-top-quad">
                    <ApplicationLinkContainer href={HELP_SIGN_IN_URL} primary>
                        <FormattedMessage
                            id={
                                isUk
                                    ? 'signIn.link.moreHelp'
                                    : 'emailFirst.helpLink.help'
                            }
                        />
                    </ApplicationLinkContainer>
                </div>
            )}
        </EmailFirstPageLayout>
    );
};
