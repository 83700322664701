import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../emailFirstForm';
import {
    EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME,
    EmailFirstPasswordFormInput,
} from '../inputs';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { useStateContext } from '../../../../modules/stateContext';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import { EditUserIdentifier } from '../../editUserIdentifier';

const activeFields = [EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME];

export const EmailFirstPasswordForm = () => {
    const userContext = useStateContext();

    const { isFederated, isSingleEntryAuth } = userContext;

    const history = useHistory();

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const {
        fieldValues,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: emailFirstPaths.details.path,
        prevRoute: emailFirstPaths.email.path,
    });

    const showExUkContent = useShowExUkContent();

    const headingId = useMemo(() => {
        if (showExUkContent) {
            return 'emailFirst.passwordForm.titleExUk';
        }

        return 'emailFirst.passwordForm.title';
    }, [showExUkContent]);

    const onEditUserIdentifierEdit = useCallback(() => {
        history.push(
            `${emailFirstPaths.email.path}${renderQueryString.call({
                ...userContext,
            })}`
        );
    }, [history, userContext, emailFirstPaths]);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={<FormattedMessage id={headingId} />}
            layoutContentContainerClassName={
                isSingleEntryAuth && 'u-margin-top'
            }
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            submitButtonLabel="button.continue.value"
        >
            {isSingleEntryAuth && (
                <EditUserIdentifier
                    userIdentifier={fieldValues?.username || ''}
                    onClick={onEditUserIdentifierEdit}
                />
            )}
            <EmailFirstPasswordFormInput
                defaultValue={fieldValues && fieldValues.password}
                shouldValidate
            />
        </EmailFirstForm>
    );
};
