import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Checkbox,
    Heading,
    InstructionContainer,
} from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import { useStateContext } from '../../../modules/stateContext';
import AuthForm from '../form';
import Text from '../../shared/text';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import {
    AUTH_PATH,
    SINGLE_ENTRY_AUTH_PATH,
} from '../../../../shared/endpoints/account';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import FormError from '../../shared/formError';
import usePreventFormResubmission from '../../../hooks/usePreventFormResubmission';
import {
    StyledInstructionList,
    StyledListIcon,
} from './magicLinkConfirmation.style';
import { useMagicLinkPolling } from '../../../hooks/auth/useMagicLinkPolling';

export const MagicLinkConfirmationBase = ({
    initialErrors,
    traceId,
    email,
    jti,
    featureToggles,
    onMagicLinkAuthenticateSubmit,
    pageOverride,
}) => {
    const userContext = useStateContext();
    const intl = useIntl();

    const magicLinkAction = `/auth/magic-link${renderQueryString.call(
        userContext,
        { traceId }
    )}`;

    const [checked, setChecked] = useState(false);

    const showCheckboxError = !!initialErrors?.confirmation && !checked;

    usePreventFormResubmission();

    const shouldNotUsePolling =
        !!initialErrors?.confirmation || !featureToggles.magicLinksPolling;

    const pollingHasFailed = useMagicLinkPolling({
        data: { email, jti },
        disablePollingOverride: shouldNotUsePolling,
    });

    const onSubmit = useCallback(
        event => {
            if (onMagicLinkAuthenticateSubmit) {
                event.preventDefault();
            }

            onMagicLinkAuthenticateSubmit(event);

            setChecked(false);
        },
        [setChecked, onMagicLinkAuthenticateSubmit]
    );

    const authPath = userContext.isSingleEntryAuth
        ? SINGLE_ENTRY_AUTH_PATH
        : AUTH_PATH;

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.checkEmail.title" />}
            showServiceIdentifiers
        >
            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.introduction" />{' '}
                <span className="u-bold">{email}</span>
            </Text>

            <Text
                className={
                    featureToggles.magicLinksPolling && 'u-margin-bottom-none'
                }
            >
                <FormattedMessage
                    id="auth.magicLink.checkEmail.activeDuration"
                    values={{
                        b: content => <span className="u-bold">{content}</span>,
                    }}
                />
            </Text>

            {!shouldNotUsePolling && !pollingHasFailed && (
                <Text>
                    <FormattedMessage id="auth.magicLink.checkEmail.pollingPageRefresh" />
                </Text>
            )}

            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.weDoThis" />
            </Text>
            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.checkSpam" />
            </Text>

            <InstructionContainer>
                <Heading level={2} fontScale="indexHeadlineSmall">
                    <FormattedMessage id="auth.magicLink.checkEmail.whatNext" />
                </Heading>

                <StyledInstructionList>
                    <Text listItem>
                        <StyledListIcon>1</StyledListIcon>
                        <FormattedMessage id="auth.magicLink.checkEmail.clickButton" />
                    </Text>
                    <Text listItem>
                        <StyledListIcon>2</StyledListIcon>
                        <FormattedMessage id="auth.magicLink.checkEmail.comeBack" />
                    </Text>
                </StyledInstructionList>
            </InstructionContainer>

            {(shouldNotUsePolling || pollingHasFailed) && (
                <AuthForm
                    action={magicLinkAction}
                    buttonTranslationKey="button.continue.value"
                    onSubmit={onSubmit}
                    pageOverride={pageOverride}
                >
                    <input
                        aria-hidden="true"
                        type="hidden"
                        value={email}
                        id="email-input"
                        name="email"
                    />
                    <input
                        aria-hidden="true"
                        type="hidden"
                        value={jti}
                        id="jti-input"
                        name="jti"
                    />
                    <Checkbox
                        label={intl.formatMessage({
                            id: 'auth.magicLink.checkEmail.confirmation',
                        })}
                        name="confirmation"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        labelSize="small"
                        variant="grayscale"
                        errorComponent={
                            showCheckboxError && (
                                <FormError
                                    error={initialErrors?.confirmation}
                                    name="confirmation"
                                    migrateToIdComponents
                                />
                            )
                        }
                    />
                </AuthForm>
            )}
            <Text className="u-margin-top-double">
                <ApplicationLinkContainer href={authPath} withState primary>
                    <FormattedMessage id="auth.magicLink.checkEmail.resendEmail" />
                </ApplicationLinkContainer>
            </Text>
            <Text>
                <ApplicationLinkContainer
                    href={() => {
                        return `${authPath}${renderQueryString.call(
                            userContext,
                            {},
                            ['userJourney']
                        )}`;
                    }}
                    primary
                >
                    <FormattedMessage id="auth.magicLink.checkEmail.usePassword" />
                </ApplicationLinkContainer>
            </Text>
        </Layout>
    );
};

MagicLinkConfirmationBase.propTypes = {
    initialErrors: PropTypes.shape({
        confirmation: PropTypes.string,
    }),
    traceId: PropTypes.string,
    email: PropTypes.string.isRequired,
    jti: PropTypes.string.isRequired,
    featureToggles: PropTypes.shape({
        magicLinksPolling: PropTypes.bool,
    }).isRequired,
    onMagicLinkAuthenticateSubmit: PropTypes.func,
    pageOverride: PropTypes.string,
};

const MagicLinkConfirmation = () => {
    const userContext = useStateContext();

    const {
        signIn: {
            data: { email, jti, traceId },
            initialErrors,
        },
        featureToggles,
    } = userContext;

    return (
        <MagicLinkConfirmationBase
            email={email}
            jti={jti}
            traceId={traceId}
            initialErrors={initialErrors}
            featureToggles={featureToggles}
        />
    );
};

export default MagicLinkConfirmation;
