import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
// eslint-disable-next-line import/no-named-as-default
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import { BASE_URL } from '../../../../shared/endpoints/session';
import { usePurpose } from '../../../context/purpose';
import { useStateContext } from '../../../modules/stateContext';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';

function getSessionUrl(ptrt: string) {
    const url = new URL(BASE_URL);

    if (ptrt) {
        url.searchParams.set('ptrt', ptrt);
    }

    url.searchParams.set('switchTld', '1');

    return url.toString();
}

export const VerifyPasswordThanks = () => {
    const {
        ptrt: { value: ptrt },
        featureToggles,
    } = useStateContext();

    const sessionUrl = getSessionUrl(ptrt);

    const showExUkContent = useShowExUkContent();

    const { isSubscription: isPurposeSubscription } = usePurpose();

    return (
        <Layout
            heading={
                <FormattedMessage
                    id={
                        showExUkContent && featureToggles?.createPasswordContent
                            ? 'cama.passwordUpdatedExUK'
                            : 'cama.passwordUpdated'
                    }
                />
            }
        >
            <div className="u-margin-top--triple">
                <ApplicationLinkContainer
                    button
                    primary
                    fullWidth
                    href={sessionUrl}
                    external
                >
                    <FormattedMessage
                        id={
                            isPurposeSubscription
                                ? 'subscription.checkoutButton.label'
                                : 'signIn.button.submit.value'
                        }
                    />
                </ApplicationLinkContainer>
            </div>
        </Layout>
    );
};
