import { useEffect, useState } from 'react';
import renderQueryString from '../../../shared/urls/renderQueryString';
import { useStateContext } from '../../modules/stateContext';
import { AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL } from '../../../shared/endpoints/account';
import { magicLinkAuthenticate } from '../../services/magicLinkAuthenticate';
import { DEFAULT_SOURCE } from '../../../shared/data/analytics';
import authPages from '../../../shared/data/authPages';
import { getSessionUrlWithUpdatedPtrt } from '../../../shared/magicLink';

export function redirectToMagicLinkErrorPage(context: string) {
    const query = context ? `?context=${context}` : '';

    window.location.assign(`${AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL}${query}`);
}

type MagicLinkAuthenticateOptions = Parameters<typeof magicLinkAuthenticate>[0];

type UseMagicLinkPollingData = Pick<
    MagicLinkAuthenticateOptions,
    'email' | 'jti'
>;

type UseMagicLinkPollingOptions = {
    data?: UseMagicLinkPollingData;
    disablePollingOverride?: boolean;
};

export function useMagicLinkPolling({
    data,
    disablePollingOverride = false,
}: UseMagicLinkPollingOptions = {}) {
    const userContext = useStateContext();

    const [pollingHasFailed, setPollingHasFailed] = useState(false);

    const tenSecondInterval = 1000 * 10;

    const fifteenMinutesInMilliseconds = 1000 * 60 * 15;

    const {
        featureToggles,
        lang,
        ptrt: { value: ptrt },
        sequenceId,
        context,
        purpose,
    } = userContext;

    const { email, jti } = data || userContext.signIn.data;

    if (
        featureToggles.magicLinksPollingAnalytics &&
        typeof window !== 'undefined'
    ) {
        document.addEventListener('visibilitychange', () => {
            document.dispatchEvent(
                new CustomEvent('bbc-user-event', {
                    detail: {
                        container: authPages.MAGIC_LINK_CONFIRMATION,
                        label: 'polling',
                        type: document.visibilityState,
                        source: DEFAULT_SOURCE,
                    },
                })
            );
        });
    }

    useEffect(() => {
        if (!featureToggles.magicLinksPolling || disablePollingOverride) {
            return undefined;
        }

        const intervalId = setInterval(async () => {
            const { sessionUrl, message } = await magicLinkAuthenticate({
                email,
                jti,
                params: renderQueryString.call(userContext),
            });

            if (sessionUrl) {
                const redirectUrl = getSessionUrlWithUpdatedPtrt({
                    sessionUrl,
                    ptrt,
                    lang,
                    sequenceId,
                    context,
                    purpose,
                });

                window.location.assign(redirectUrl);
            } else if (message === 'expiredTokenError') {
                redirectToMagicLinkErrorPage(context);
            } else if (
                message === 'routeNotFound' ||
                message === 'clientError'
            ) {
                setPollingHasFailed(true);
                clearInterval(intervalId);

                if (
                    featureToggles.magicLinksPollingAnalytics &&
                    message === 'clientError'
                ) {
                    document.dispatchEvent(
                        new CustomEvent('bbc-user-event', {
                            detail: {
                                container: authPages.MAGIC_LINK_CONFIRMATION,
                                label: 'polling',
                                type: 'fallbackdisplayed',
                                source: DEFAULT_SOURCE,
                            },
                        })
                    );
                }
            } else if (message === 'serverError') {
                window.location.assign('/account/error');
            }
        }, tenSecondInterval);

        setTimeout(() => {
            clearInterval(intervalId);
            redirectToMagicLinkErrorPage(context);
        }, fifteenMinutesInMilliseconds);

        return () => {
            clearInterval(intervalId);
        };
    }, [ptrt, purpose]);

    return pollingHasFailed;
}
