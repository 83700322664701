import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
    AccountLayout,
    AccountLayoutSection,
    AccountBackgroundLayout,
    AccountFooter,
    BbcFooter,
} from '@bbc-account/id-components';
import CloseButton from './closeButton';
import Track from '../shared/analytics/track';
import Spinner from '../shared/spinner';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import { HELP_ACCOUNT_URL } from '../../../shared/endpoints/usingTheBbc';
import { useStateContext } from '../../modules/stateContext';
import BbcFooterLinks from './bbcFooterLinks';
import BbcFooterCopyright from './bbcFooterCopyright';
import { BackButton } from '../buttons';

const Layout = ({
    children,
    closeButton,
    'data-testid': dataTestId,
    heading,
    layoutContentContainerClassName,
    linkTarget,
    onBackButtonClick,
    pageClassName,
    pageId,
    secondaryContent,
    showBackButton,
    showLoadingSpinner,
    showServiceIdentifiers,
}) => {
    const {
        isFederated,
        isNativeMobileApplication,
        contextualisation: { positionClass },
        context,
        location: { isUk },
        featureToggles: { backgroundLayoutOffset },
    } = useStateContext();

    const shouldShowServiceIdentifiers = showServiceIdentifiers && isUk;

    const bypassChameleon = context === 'childrens' || context === 'profiles';

    const pageClasses = classnames('page page--chameleon', pageClassName);

    const shouldBeLogoLink = !isFederated && isUk;

    const accountBackgroundLayoutOffsetLeft = !backgroundLayoutOffset || isUk;

    return (
        <>
            <div data-testid={dataTestId} id={pageId} className={pageClasses}>
                <AccountBackgroundLayout
                    bypassChameleon={bypassChameleon}
                    backgroundBottomRight={Boolean(positionClass)}
                    offsetLeft={accountBackgroundLayoutOffsetLeft}
                >
                    <AccountLayout
                        contentContainerClassName={
                            layoutContentContainerClassName
                        }
                        title={heading}
                        isBbcLogoLink={shouldBeLogoLink}
                        showServiceIdentifiers={shouldShowServiceIdentifiers}
                    >
                        {showLoadingSpinner && <Spinner fillContainer />}
                        {children}
                    </AccountLayout>
                    {secondaryContent && (
                        <AccountLayoutSection hasTopBorder isCentreAligned>
                            {secondaryContent}
                        </AccountLayoutSection>
                    )}
                </AccountBackgroundLayout>
                {!isFederated && (
                    <AccountFooter>
                        <ApplicationLinkContainer
                            external
                            href={HELP_ACCOUNT_URL}
                            target={linkTarget}
                            tertiary
                        >
                            <FormattedMessage id="common.link.footer.help.value" />
                        </ApplicationLinkContainer>
                    </AccountFooter>
                )}
                {showBackButton && (
                    <Track ignoreViews name="back-button-link">
                        <BackButton
                            data-testid="layout-back-button"
                            onClick={onBackButtonClick}
                        />
                    </Track>
                )}
                {!isNativeMobileApplication && !isFederated && closeButton}
            </div>
            {!isFederated && !isNativeMobileApplication && (
                <BbcFooter
                    links={<BbcFooterLinks target={linkTarget} />}
                    copyright={<BbcFooterCopyright target={linkTarget} />}
                />
            )}
        </>
    );
};

Layout.displayName = 'Layout';

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    closeButton: PropTypes.node,
    'data-testid': PropTypes.string,
    layoutContentContainerClassName: PropTypes.string,
    linkTarget: PropTypes.string,
    heading: PropTypes.node.isRequired,
    onBackButtonClick: BackButton.propTypes.onClick,
    pageClassName: PropTypes.string,
    pageId: PropTypes.string,
    secondaryContent: PropTypes.node,
    showBackButton: PropTypes.bool,
    showLoadingSpinner: PropTypes.bool,
    showServiceIdentifiers: PropTypes.bool,
};

Layout.defaultProps = {
    closeButton: <CloseButton />,
    pageClassName: null,
    pageId: null,
    secondaryContent: null,
    showLoadingSpinner: false,
    showServiceIdentifiers: false,
};

export default Layout;
