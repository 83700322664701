import { updatePtrtWithIntermediateSuccessPage } from './updatePtrtWithIntermediateSuccessPage';

type UpdatePtrtWithIntermediateSuccessPageOptions = Parameters<
    typeof updatePtrtWithIntermediateSuccessPage
>[0];

type GetSessionUrlWithUpdatedPtrtOptions = Pick<
    UpdatePtrtWithIntermediateSuccessPageOptions,
    'context' | 'lang' | 'ptrt' | 'purpose' | 'sequenceId'
> & {
    sessionUrl: string;
};

export function getSessionUrlWithUpdatedPtrt({
    context,
    lang,
    ptrt,
    purpose,
    sequenceId,
    sessionUrl,
}: GetSessionUrlWithUpdatedPtrtOptions) {
    const redirectUrl = new URL(sessionUrl);

    const stateString = redirectUrl.searchParams.get('state') || '{}';

    let state;

    try {
        state = JSON.parse(stateString);
    } catch (error) {
        state = {};
    }

    state.ptrt = updatePtrtWithIntermediateSuccessPage({
        context,
        lang,
        ptrt,
        purpose,
        sequenceId,
    });

    redirectUrl.searchParams.set('state', JSON.stringify(state));

    return redirectUrl.toString();
}
