type Ptrt = {
    isDefault?: boolean;
    value: string;
};

export function getPtrtWithMarketingPreference(
    ptrt?: Ptrt,
    marketingPreferences: string = ''
) {
    if (!ptrt) {
        return ptrt;
    }

    const url: URL = new URL(ptrt.value);

    url.searchParams.set('accountMarketingPreferences', marketingPreferences);

    return {
        ...ptrt,
        isDefault: false,
        value: url.toString(),
    };
}
