// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore id-components does not have type declarations. Replace this component with id-components v2 when ready.
import { Heading } from '@bbc-account/id-components';
import React, {
    ComponentProps,
    DetailedHTMLProps,
    HTMLAttributes,
    useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import usePreventFormResubmission from '../../../hooks/usePreventFormResubmission';
import Text from '../../shared/text';
import { useStateContext } from '../../../modules/stateContext';
import MagicLinkCta from '../../shared/magicLinkCta';

type MagicLinkCtaProps = ComponentProps<typeof MagicLinkCta>;

type MagicLinkCopyProps = DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> &
    Partial<Pick<MagicLinkCtaProps, 'email' | 'onSubmit'>>;

export function MagicLinkCopy({
    email,
    onSubmit,
    ...props
}: MagicLinkCopyProps) {
    const userContext = useStateContext();

    usePreventFormResubmission();

    const {
        location: { isUk },
        signIn,
        featureToggles: { checkHasPassword },
    } = userContext;

    const hasPassword = signIn?.context?.hasPassword;

    const username = signIn?.data?.username;

    const headingTitle = useMemo(() => {
        if (isUk || checkHasPassword === false || hasPassword === true) {
            return 'auth.magicLink.cta.hasPassword.title';
        }

        return 'auth.magicLink.cta.noPassword.title';
    }, [checkHasPassword, isUk, hasPassword]);

    return (
        <div {...props}>
            <Heading fontScale="indexHeadlineMedium" level={2}>
                <FormattedMessage id={headingTitle} />
            </Heading>
            <Text className="u-margin-top">
                <FormattedMessage id="auth.magicLink.cta.text" />
            </Text>
            <MagicLinkCta email={email || username} onSubmit={onSubmit} />
        </div>
    );
}
