import { AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL } from '../endpoints/account';

type UpdatePtrtWithIntermediateSuccessPageOptions = {
    context?: string;
    lang?: string;
    ptrt: string;
    purpose?: string;
    sequenceId?: string;
};

export function updatePtrtWithIntermediateSuccessPage({
    context,
    lang,
    ptrt,
    purpose,
    sequenceId,
}: UpdatePtrtWithIntermediateSuccessPageOptions) {
    const successUrl = new URL(AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL);

    successUrl.searchParams.set('ptrt', ptrt);

    Object.entries({
        context,
        lang,
        purpose,
        sequenceId,
    }).forEach(([name, value]) => {
        if (typeof value !== 'undefined') {
            successUrl.searchParams.set(name, value);
        }
    });

    return successUrl.toString();
}
