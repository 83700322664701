const REGISTRATION_START_EVENT = {
    name: 'registration.start',
    data: {
        event: {
            category: 'registration',
            action: 'start',
        },
    },
};

const REGISTRATION_COMPLETION_EVENT = {
    name: 'registration.complete',
    data: {
        event: {
            category: 'registration',
            action: 'complete',
        },
    },
};

const REGISTRATION_PASSWORDLESS_START_EVENT = {
    name: 'registration.start',
    data: {
        event: {
            category: 'registration',
            action: 'start',
            subcategory: 'PASSWORDLESS',
        },
    },
};

const REGISTRATION_PASSWORDLESS_COMPLETION_EVENT = {
    name: 'registration.complete',
    data: {
        event: {
            category: 'registration',
            action: 'complete',
            subcategory: 'PASSWORDLESS',
        },
    },
};

export {
    REGISTRATION_START_EVENT,
    REGISTRATION_COMPLETION_EVENT,
    REGISTRATION_PASSWORDLESS_START_EVENT,
    REGISTRATION_PASSWORDLESS_COMPLETION_EVENT,
};
