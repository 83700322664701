import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const Text = props => {
    const { className, heading, introduction, listItem, ...childProps } = props;

    const classes = classnames('text', className, {
        'text--introduction': introduction,
        'text--heading': heading,
    });

    return listItem ? (
        <li {...childProps} className={classes} />
    ) : (
        <p {...childProps} className={classes} />
    );
};

Text.displayName = 'Text';

Text.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    heading: PropTypes.bool,
    introduction: PropTypes.bool,
    listItem: PropTypes.bool,
};

Text.defaultProps = {
    heading: false,
    introduction: false,
    listItem: false,
};

export default Text;
